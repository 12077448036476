import classNames from 'classnames';
import React from 'react';
import Style from 'styles/components/comachicart/maintenance.module.scss';
import { useEffect, useRef } from 'react';

const Maintenance = ({data}) => {
  // JSON内のクラスを動的に設定
  const containerRef = useRef(null);
  useEffect(() => {
      if (containerRef.current) {
        const alignRight = containerRef.current.querySelector('.ql-align-right');
        if (alignRight) {
          alignRight.style.textAlign = 'right';
        }
        const alignCenter = containerRef.current.querySelector('.ql-align-center');
        if (alignCenter) {
          alignCenter.style.textAlign = 'center';
        }
        const sizeSmall = containerRef.current.querySelector('.ql-size-small');
        if (sizeSmall) {
          sizeSmall.style.fontSize = '1rem';
        }
        const sizeLarge = containerRef.current.querySelector('.ql-size-large');
        if (sizeLarge) {
          sizeLarge.style.fontSize = '2rem';
        }
      }
    }, []);

    const contentHtml = {
      __html: data.content
        .replace(/<p style="margin: 0;"><\/p>/g, '<br />')
    };
    return (
        <section className={Style.maintenance}>
          <h2 className={classNames(Style.catch)}>
            <span>{data.title}</span>
          </h2>
          <p
            ref={containerRef}
            className={classNames(Style, Style.maintenance__notice, Style.mt40, Style.mb40)}
            dangerouslySetInnerHTML={
    contentHtml}
          />
        </section>
      );
};
export default Maintenance;